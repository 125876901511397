import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

import { Icon, Menu, MenuItem } from "../../..";
import { Anchor, StyleActions, StyleStatus } from "../../types";

interface StyleActionsMenuProps extends StyleActions {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  styleStatus: StyleStatus;
  anchor?: Anchor;
}

export const StyleActionsMenu = (props: StyleActionsMenuProps) => {
  const {
    open,
    anchor,
    onClose,
    onEdit,
    onForge,
    onUnlink,
    onView,
    onDelete,
    onImport,
    onDuplicate,
    onCancelTraining,
  } = props;

  const handleMenuOptionSelect =
    (fn: (event: React.MouseEvent) => void) => (event: React.MouseEvent) => {
      onClose(event);

      if (fn) {
        fn(event);
      }
    };

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorPosition={{
        top: anchor ? anchor.y : 0,
        left: anchor ? anchor.x : 0,
      }}
      anchorReference="anchorPosition"
    >
      {onForge && (
        <MenuItem
          icon={<Icon icon={regular("arrow-up-right")} />}
          label="Forge with Style"
          onClick={handleMenuOptionSelect(onForge)}
        />
      )}
      {onView && (
        <MenuItem
          icon={<Icon icon={regular("location")} />}
          label="View Style"
          onClick={handleMenuOptionSelect(onView)}
        />
      )}
      {onCancelTraining && (
        <MenuItem
          icon={<Icon icon={regular("circle-stop")} />}
          label="Cancel"
          onClick={handleMenuOptionSelect(onCancelTraining)}
        />
      )}
      {onEdit && (
        <MenuItem
          icon={<Icon icon={regular("pencil-square")} />}
          label="Edit"
          onClick={handleMenuOptionSelect(onEdit)}
        />
      )}
      {onImport && (
        <MenuItem
          icon={<Icon icon={regular("file-import")} />}
          label="Import Style"
          onClick={handleMenuOptionSelect(onImport)}
        />
      )}
      {onUnlink && (
        <MenuItem
          icon={<Icon icon={regular("unlink")} />}
          label="Unlink Style"
          onClick={handleMenuOptionSelect(onUnlink)}
        />
      )}
      {onDuplicate && (
        <MenuItem
          icon={<Icon icon={regular("copy")} />}
          label="Duplicate"
          onClick={handleMenuOptionSelect(onDuplicate)}
        />
      )}
      {onDelete && (
        <MenuItem
          icon={<Icon icon={regular("trash")} />}
          label="Delete"
          onClick={handleMenuOptionSelect(onDelete)}
        />
      )}
    </Menu>
  );
};
