import { createSvgIcon } from "@mui/material";

export const ExportWithoutBackgroundIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.26877 0.399414H3.4719C1.761 0.399414 0.400024 1.79581 0.400024 3.47129V4.26816C0.400024 4.64984 0.699604 4.94941 1.08127 4.94941C1.42366 4.94941 1.76252 4.65579 1.76252 4.26816V3.47129C1.76252 2.5563 2.52718 1.76191 3.4719 1.76191H4.26877C4.61116 1.76191 4.95002 1.46829 4.95002 1.08066C4.95002 0.899067 4.86074 0.730025 4.74008 0.609364C4.61941 0.488702 4.45037 0.399414 4.26877 0.399414Z"
      fill="currentColor"
    />
    <path
      d="M1.76252 6.39316C1.76252 6.21157 1.67324 6.04252 1.55257 5.92186C1.43191 5.8012 1.26287 5.71191 1.08127 5.71191C0.693651 5.71191 0.400024 6.05078 0.400024 6.39316V9.58066C0.400024 9.96233 0.699604 10.2619 1.08127 10.2619C1.42366 10.2619 1.76252 9.96829 1.76252 9.58066V6.39316Z"
      fill="currentColor"
    />
    <path
      d="M15.3651 5.92186C15.4857 6.04252 15.575 6.21157 15.575 6.39316V9.58066C15.575 9.96829 15.2362 10.2619 14.8938 10.2619C14.5121 10.2619 14.2125 9.96233 14.2125 9.58066V6.39316C14.2125 6.05078 14.5062 5.71191 14.8938 5.71191C15.0754 5.71191 15.2444 5.8012 15.3651 5.92186Z"
      fill="currentColor"
    />
    <path
      d="M14.2125 4.26816C14.2125 4.64984 14.5121 4.94941 14.8938 4.94941C15.2362 4.94941 15.575 4.65579 15.575 4.26816V3.47129C15.575 1.7947 14.1797 0.399414 12.5031 0.399414H11.7063C11.3187 0.399414 11.025 0.738277 11.025 1.08066C11.025 1.46233 11.3246 1.76191 11.7063 1.76191H12.5031C13.4164 1.76191 14.2125 2.55804 14.2125 3.47129V4.26816Z"
      fill="currentColor"
    />
    <path
      d="M15.575 11.7057V12.5025C15.575 14.2134 14.1786 15.5744 12.5031 15.5744H11.7063C11.3246 15.5744 11.025 15.2748 11.025 14.8932C11.025 14.5508 11.3187 14.2119 11.7063 14.2119H12.5031C13.4181 14.2119 14.2125 13.4473 14.2125 12.5025V11.7057C14.2125 11.3633 14.5062 11.0244 14.8938 11.0244C15.0754 11.0244 15.2444 11.1137 15.3651 11.2344C15.4857 11.355 15.575 11.5241 15.575 11.7057Z"
      fill="currentColor"
    />
    <path
      d="M1.76252 12.5025V11.7057C1.76252 11.5241 1.67324 11.355 1.55257 11.2344C1.43191 11.1137 1.26287 11.0244 1.08127 11.0244C0.693651 11.0244 0.400024 11.3633 0.400024 11.7057V12.5025C0.400024 14.2123 1.7621 15.5744 3.4719 15.5744H4.26877C4.61116 15.5744 4.95002 15.2808 4.95002 14.8932C4.95002 14.7116 4.86074 14.5425 4.74008 14.4219C4.61941 14.3012 4.45037 14.2119 4.26877 14.2119H3.4719C2.52545 14.2119 1.76252 13.449 1.76252 12.5025Z"
      fill="currentColor"
    />
    <path
      d="M6.39377 15.5744C6.0121 15.5744 5.71252 15.2748 5.71252 14.8932C5.71252 14.5508 6.00615 14.2119 6.39377 14.2119H9.58127C9.76287 14.2119 9.93191 14.3012 10.0526 14.4219C10.1732 14.5425 10.2625 14.7116 10.2625 14.8932C10.2625 15.2808 9.92366 15.5744 9.58127 15.5744H6.39377Z"
      fill="currentColor"
    />
    <path
      d="M6.39377 0.399414C6.00615 0.399414 5.71252 0.738277 5.71252 1.08066C5.71252 1.46233 6.0121 1.76191 6.39377 1.76191H9.58127C9.92366 1.76191 10.2625 1.46829 10.2625 1.08066C10.2625 0.899067 10.1732 0.730025 10.0526 0.609364C9.93191 0.488702 9.76287 0.399414 9.58127 0.399414H6.39377Z"
      fill="currentColor"
    />
    <path
      d="M11.5225 11.2002H4.31006V12.552H11.5225L11.5225 11.2002Z"
      fill="currentColor"
    />
    <path
      d="M7.92158 10.9369L4.25467 7.27004L5.20927 6.31545L7.24567 8.35185V3.13936H8.59749V8.35185L10.6339 6.31545L11.5885 7.27004L7.92158 10.9369Z"
      fill="currentColor"
    />
  </svg>,
  "ExportWithoutBackgroundIcon",
);
