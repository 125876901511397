import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface MenuProps extends MuiMenuProps {}

export const Menu = styled(MuiMenu)<MenuProps>(() => {
  const theme = useTheme();
  const { spacing, palette } = theme;

  return {
    "& .MuiPaper-root": {
      minWidth: 210,
      backgroundColor: palette.secondary[900],
      border: `1px solid ${palette.secondary[700]}`,
      borderRadius: "8px",
      padding: spacing(1, 0),

      "& .MuiDivider-root": {
        borderColor: palette.secondary[700],
        margin: spacing(2, 0),
      },
    },
  };
});
