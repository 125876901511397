import { ChipProps } from "@mui/material";
import { SxProps } from "@mui/system";

import { Chip } from "..";

interface ShortcutIndicatorProps {
  keys: string[];
  minWidth?: number;
  sx?: SxProps;
  color?: ChipProps["color"];
}

export const ShortcutIndicator = ({
  keys,
  minWidth,
  sx,
  color = "secondary",
}: ShortcutIndicatorProps) => {
  const isSingleCharacter = keys.length === 1 && keys[0].length === 1;
  const isNotSingleCharacter = keys.length >= 2;
  const fixedWidth = isNotSingleCharacter
    ? "fit-content"
    : isSingleCharacter
      ? "20px"
      : "28px";

  const chipWidth = minWidth ? minWidth : fixedWidth;

  return (
    <Chip
      label={keys.join(" ")}
      variant="filled"
      color={color}
      size="small"
      sx={{
        ...sx,
        maxWidth: chipWidth,
        borderRadius: "4px",
        padding: "4px",
        pointerEvents: "none",
        userSelect: "none",

        "& .MuiChip-label": {
          textOverflow: "clip",
          whiteSpace: "nowrap",
          minWidth: chipWidth,
          textAlign: "center",
        },
      }}
    />
  );
};
