import { Tabs as MuiTabs, TabsOwnProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

interface TabsProps extends TabsOwnProps {
  underline?: boolean;
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
}

const getCommonStyles = (theme, size) => {
  const { typography, spacing } = theme;

  const sizeMap = {
    large: 9,
    medium: 7,
    small: 6,
  };

  return {
    width: "fit-content",

    "& .MuiTabs-flexContainer .MuiTab-root": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: spacing(sizeMap[size]),
      padding: size === "large" ? spacing(3) : spacing(1, 2),
      columnGap: "5px",
      fontSize:
        size === "small" ? typography.mini.fontSize : typography.small.fontSize,
      textTransform: "none",
      borderWidth: "0px",
      borderRadius: spacing(1),

      "& .MuiTab-iconWrapper": {
        marginBottom: 0,
      },
    },
  };
};

const getUnderlineStyles = (theme) => {
  const { palette, spacing } = theme;

  return {
    backgroundColor: "transparent",

    "& .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root": {
      marginBottom: spacing(4),
      fontWeight: 500,
      color: palette.secondary[300],
      marginRight: spacing(2),

      "&:last-child": {
        marginRight: 0,
      },

      "&:hover": {
        backgroundColor: palette.secondary[900],
        boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700"]}`,
      },

      "&.Mui-selected": {
        backgroundColor: palette.secondary[800],
        boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["600"]}`,
        color: palette.secondary[50],
      },
    },

    "& .MuiTabs-indicator": {
      height: "1px",
      backgroundColor: palette.secondary[500],
    },
  };
};

const getDefaultTabStyles = (theme, color) => {
  const { palette, spacing } = theme;

  return {
    borderRadius: spacing(1),
    "& .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root": {
      fontWeight: 400,
      color: palette.secondary[300],

      "&:hover": {
        backgroundColor:
          color === "primary"
            ? palette.secondary["700/50"]
            : palette.secondary["800/50"],
      },

      "&:last-child": {
        marginRight: 0,
      },

      "&.Mui-selected": {
        backgroundColor:
          color === "primary" ? palette.secondary[700] : palette.secondary[800],
        boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["500/50"]}`,
        color: palette.secondary[50],
      },
    },

    "& .MuiTabs-indicator": {
      display: "none",
    },
  };
};

const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: (prop) =>
    prop !== "underline" && prop !== "color" && prop !== "size",
})<TabsProps>(({
  theme,
  underline = false,
  color = "secondary",
  size = "medium",
}) => {
  const commonStyles = getCommonStyles(theme, size);
  const tabStyles = underline
    ? getUnderlineStyles(theme)
    : getDefaultTabStyles(theme, color);
  return { ...commonStyles, ...tabStyles };
});

export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  ({ underline = false, size = "medium", ...rest }: TabsProps, ref) => {
    return <StyledTabs {...rest} ref={ref} underline={underline} size={size} />;
  },
);
