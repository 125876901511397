import { Box, styled } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  width: "fit-content",
  pointerEvents: "none",
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.secondary[700],
  boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary[600]}, 0px 7px 32px rgba(0, 0, 0, 0.35)`,
  borderRadius: 999,
  margin: "0 auto",
}));

const Dots = styled("div")(({ theme }) => ({
  width: 32,
  aspectRatio: "2",
  "--_g": `no-repeat radial-gradient(circle closest-side, ${theme.palette.secondary[300]} 90%, transparent)`,
  background: `
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%
  `,
  backgroundSize: "calc(100%/3) 50%",
  animation: "l3 1s infinite linear",
  "@keyframes l3": {
    "20%": { backgroundPosition: "0% 0%, 50% 50%, 100% 50%" },
    "40%": { backgroundPosition: "0% 100%, 50% 0%, 100% 50%" },
    "60%": { backgroundPosition: "0% 50%, 50% 100%, 100% 0%" },
    "80%": { backgroundPosition: "0% 50%, 50% 50%, 100% 100%" },
  },
}));

export const InfiniteScrollFetchingIndicator = (props) => (
  <Container {...props}>
    <Dots />
  </Container>
);
