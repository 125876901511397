import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

type CustomTextAreaProps = MuiTextFieldProps & {
  fontSize?: string | number;
  fontColor?: string;
  placeholderColor?: string;
};

const extraProps: PropertyKey[] = ["fontSize", "fontColor", "placeholderColor"];

const StyledTextArea = styled(MuiTextField, {
  shouldForwardProp: (prop) => !extraProps.includes(prop),
})<CustomTextAreaProps>(({ theme, fontSize, fontColor, placeholderColor }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: "transparent",
  },
  "& .MuiInputBase-input": {
    padding: 0,
    fontSize,
    color: fontColor,
    "&::placeholder": {
      color: placeholderColor || theme.palette.secondary[300],
      opacity: 0.7,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

export const TextArea = React.forwardRef<HTMLDivElement, CustomTextAreaProps>(
  (
    {
      fontSize = "inherit",
      fontColor = "inherit",
      placeholderColor,
      rows = 4,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledTextArea
        ref={ref}
        multiline
        rows={rows}
        variant="outlined"
        fontSize={fontSize}
        fontColor={fontColor}
        placeholderColor={placeholderColor}
        InputProps={{
          ...rest?.InputProps,
          multiline: true,
          inputComponent: TextareaAutosize,
        }}
        {...rest}
      />
    );
  },
);
