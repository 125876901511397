import { icon as faIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import {
  ListItemText as MuiListItemText,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  Theme,
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import React, { forwardRef } from "react";

import { Icon, ListItemIcon } from "../..";

type Variant = "primary" | "secondary" | "tertiary";

interface CommonMenuItemProps extends MuiMenuItemProps {
  label: string;
  secondaryLabel?: string;
  variant?: Variant;
  icon?: React.ReactElement;
  hasSubMenu?: boolean;
  shortcut?: React.ReactNode;
  href?: string;
  target?: string;
}

type MenuItemProps = CommonMenuItemProps;

const StyledListItemText = styled(MuiListItemText)(({ theme }) => {
  const { typography } = theme as Theme;

  return {
    display: "flex",
    justifyContent: "space-between",

    "& .MuiListItemText-primary": {
      fontSize: typography.small.fontSize,
    },

    "& .MuiListItemText-secondary": {
      fontSize: typography.small.fontSize,
      color: theme.palette.secondary[300],
      textAlign: "right",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: theme.spacing(3),
      fontWeight: "normal",
    },
  };
});

const StyledMenuItem = styled(MuiMenuItem, {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant: Variant }>(({ theme, variant }) => ({
  position: "relative",
  height: 28,
  border: `1px solid transparent`,
  borderRadius: "4px",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),

  "& .MuiListItemText-root span": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  "&:hover": {
    backgroundColor: theme.palette.secondary["600/50"],
    borderColor: theme.palette.secondary["500/25"],
  },

  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.secondary["600/50"],
    borderColor: theme.palette.secondary["500/25"],
  },

  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary["600/75"],
    borderColor: theme.palette.secondary["500/25"],
    color: theme.palette.secondary[50],
  },

  "&.Mui-selected:hover, &.MuiMenuItem-root.Mui-selected.Mui-focusVisible": {
    backgroundColor: theme.palette.secondary["600/75"],
    borderColor: theme.palette.secondary["500/25"],
    color: theme.palette.secondary[50],
  },

  ...(variant === "primary" && {
    ".MuiListItemIcon-root": {
      color: theme.palette.secondary[100],
    },

    ".MuiListItemText-root": {
      color: theme.palette.secondary[100],
      fontWeight: 500,
    },
  }),

  ...(variant === "secondary" && {
    ".MuiListItemText-root": {
      color: theme.palette.secondary[100],
      fontWeight: 400,
    },

    ".MuiListItemIcon-root": {
      color: theme.palette.secondary[300],
    },
  }),

  ...(variant === "tertiary" && {
    ".MuiListItemText-root": {
      color: theme.palette.secondary[300],
      fontWeight: 400,
    },

    ".MuiListItemIcon-root": {
      color: theme.palette.secondary[300],
    },
  }),
}));

const StyledSubMenuIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.secondary[300],
}));

const StyledSelectedIcon = styled(Icon)(
  ({ theme }: { theme: Theme; icon: IconDefinition }) => ({
    color: theme.palette.secondary[50],
    fontSize: theme.typography.small.fontSize,
  }),
);

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  (props: MenuItemProps, ref) => {
    const {
      icon,
      label,
      secondaryLabel,
      hasSubMenu,
      selected,
      shortcut,
      variant = "secondary",
      ...rest
    } = props;

    const hasEndContent = hasSubMenu || shortcut || selected;
    const showSelectedIndicator = selected && !shortcut && !hasSubMenu;

    return (
      <StyledMenuItem variant={variant} selected={selected} ref={ref} {...rest}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <StyledListItemText primary={label} secondary={secondaryLabel} />
        {hasEndContent && (
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            {shortcut}
            {hasSubMenu && (
              <StyledSubMenuIcon
                size="small"
                icon={faIcon({
                  name: "caret-right",
                  family: "sharp",
                  style: "solid",
                })}
              />
            )}
            {showSelectedIndicator && (
              <StyledSelectedIcon
                icon={faIcon({
                  name: "check",
                  family: "sharp",
                  style: "solid",
                })}
              />
            )}
          </Stack>
        )}
      </StyledMenuItem>
    );
  },
);
